<template>
  <v-list-item
    :key="index"
    v-bind="attrs" 
    v-on="on"
    @click="onClickMenuHandler"
  >
    <v-list-item-icon
      class="d-flex flex-column"
    >
      <v-badge
        overlap
        color="red darken-2"
        icon="mdi-exclamation-thick"
      >
        <v-icon
          :class="isDark ? 'white--text' : 'grey--text text--darken-4'"
          dark
          :large="!iconLabel"
        >
          {{ icon }}
        </v-icon>
      </v-badge>
      {{ iconLabel }}
    </v-list-item-icon>

    <v-list-item-content class="pa-1">
      <v-list-item-title class="d-flex align-center justify-space-between">
        <span class="d-flex align-center">
          <h3
            class="font-weight-bold red--text text--darken-2"
          >
            {{ title }}
          </h3>
        </span>
        <p
          class="pb-4 mb-0 grey--text"
          style="font-size: 14px;"
        >
          {{ formatDate(date) }}
        </p>
      </v-list-item-title>

      <v-list-item-subtitle
        class="font-weight-medium d-flex align-center justify-space-between"
        :class="colorText"
        style="text-align: initial;"
      >
        <p>
          {{ event.wheelPosition.toUpperCase() }} {{ subtitle }} 
        </p>
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- <v-list-item-action>
      <v-btn color="amber" icon @click.stop="showCommentDialog()">
        <v-icon>mdi-file-edit-outline</v-icon>
      </v-btn>
    </v-list-item-action> -->

    <gm-comment-dialog v-model="commentDialog" event-type="tpms" :item="selectedItem" />
  </v-list-item>    
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import CommentDialog from 'Components/monitor/dialogs/CommentDialog.vue'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {
    'gm-comment-dialog': CommentDialog
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconLabel: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: () => 0
    },
    date: {
      type: Number,
      default: () => undefined
    },
    value: {
      type: Number,
      default: () => 0.0
    },
    percentage: {
      type: String,
      default: () => '0.0'
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    eventType: {
      type: String,
      default: () => ''
    },
    event: {
      type: Object,
      default: () => {}
    },
    onClickMenu: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      commentDialog: false,
      selectedItem: {}
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText'])
  },
  methods: {
    formatDate (date) {
      if (date) {
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    },
    onClickHandler () {
      this.onClick(this.index)
    },
    onClickMenuHandler () {
      this.onClickMenu(this.event)
    },
    showCommentDialog (item) {
      this.selectedItem = Object.assign({}, item)
      this.commentDialog = true
    },
    badgeColor (state) {
      switch (state) {
        case 'PENDING':
          return 'warning'
        case 'closed':
          return 'success'
        default:
          return 'primary'
      }
    },
    formatSubtitleForItem (event) {
      return `${this.$t('tpmsWarning.wheelPosition')}: ${event.wheelPosition} - ${this.$t(`tpmsWarning.${event.eventType}`)}`
    }
  }
}
</script> 